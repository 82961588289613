/**
 * @file 快速开始组件的 store
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLatest} from 'react-use';

import {
    IQuickInstallSelectorGroup,
    IQuickInstallItem, IQuickInstallListCache
} from './type.d';
import React from 'react';


// 更新选项的禁用状态
// 阻止用户做出某种选择使得结果为空。
// 本方法会遍历 quickInstallList ，根据已选项将部分未选项禁用，
// 这个过程可以看做是一种搜索过滤的过程，根据用户的选择过滤出 quickInstallList 中的一些数据
const updateDisabledState = (
    checked: string[],
    quickInstallSelector: IQuickInstallSelectorGroup[],
    quickInstallList: IQuickInstallItem[],
    checkedQuickInstallListCache: { [key: string]: IQuickInstallItem[] }
): { disabled: string[], checkedItem: IQuickInstallItem[] } => {
    const newDisabledSet: Set<string> = new Set();
    // 遍历 quickInstallSelector 获取所有出现过的 optionKey
    quickInstallSelector.forEach(group => {
        group.list.forEach(item => {
            newDisabledSet.add(item.optionKey);
        });
    });

    // 当前选中状态对应的搜索结果
    const checkedQuickInstallList = (() => {
        const checkedIndex = checked.sort().join(',');

        if (checkedQuickInstallListCache[checkedIndex] && checkedQuickInstallListCache[checkedIndex].length > 0) {
            return checkedQuickInstallListCache[checkedIndex];
        }
        const list = quickInstallList.filter(item => {
            return checked.every(key => item.indexSet.has(key));
        });
        checkedQuickInstallListCache[checkedIndex] = list;

        return list;
    })();

    // 决定某一个group里哪些选项被禁用，是根据其他gorup选项选中状态决定
    // 遍历group，获取每一个group中选项的禁用状态
    quickInstallSelector.forEach((group) => {
        const thisGroupOptionKeys = group.list.map(item => item.optionKey);
        const thisGroupOptionKeysSet = new Set(thisGroupOptionKeys);
        // 除了自己group以外的选中状态
        const checkedExpectThisGroup = (() => {
            const checkedExpectThisGroupSet = new Set(checked);
            thisGroupOptionKeys.forEach((optionKey) => {
                checkedExpectThisGroupSet.delete(optionKey);
            });
            return Array.from(checkedExpectThisGroupSet);
        })();
        // 对应的搜索结果，这里对搜索结果进行缓存以便提升运行速度
        const availableQuickInstallList = (() => {
            const checkedExpectThisGroupIndex = checkedExpectThisGroup.sort().join(',');
            if (checkedQuickInstallListCache[checkedExpectThisGroupIndex]) {
                return checkedQuickInstallListCache[checkedExpectThisGroupIndex];
            }
            const list = quickInstallList.filter(item => {
                return checkedExpectThisGroup.every(key => item.indexSet.has(key));
            });
            checkedQuickInstallListCache[checkedExpectThisGroupIndex] = list;
            return list;
        })();

        const thisGroupEnableOptionKeysSet = new Set<string>();
        availableQuickInstallList.forEach((item) => {
            item.indexSet.forEach((key) => {
                if (thisGroupOptionKeysSet.has(key)) {
                    thisGroupEnableOptionKeysSet.add(key);
                }
            });
        });
        thisGroupEnableOptionKeysSet.forEach((key) => {
            newDisabledSet.delete(key);
        });
    });
    return {
        disabled: Array.from(newDisabledSet),
        checkedItem: checkedQuickInstallList
    };
};

const useQuickInstallHooks = () => {
    const [suffixText, setSuffixText] = useState<string>('');
    const [quickInstallList, setQuickInstallList] = useState<IQuickInstallItem[]>([]);
    const [quickInstallSelector, setQuickInstallSelector] = useState<IQuickInstallSelectorGroup[]>([]);
    const quickInstallSelectorRef = useLatest(quickInstallSelector);
    // 选项对应的搜索结果的缓存，用来提高运行速度
    const quickInstallCacheRef = useRef<IQuickInstallListCache>({});
    // 当结果列表变化的时候就清空缓存
    useMemo(() => {
        quickInstallCacheRef.current = {};
        return quickInstallList;
    }, [quickInstallList]);

    const [checked, setChecked] = useState<string[]>(() => {
        let res = [];
        if (typeof (localStorage) === 'object') {
            const resJson = localStorage.getItem('quickInstallHooksChecked');
            if (resJson) {
                try {
                    const r = JSON.parse(resJson);
                    res = Array.isArray(r) ? r : [];
                }
                catch (err: any) {
                }
            }
        }
        return res;
    });

    const {
        disabled,
        checkedItem
    } = useMemo(() => {
        return updateDisabledState(checked, quickInstallSelector, quickInstallList, quickInstallCacheRef.current);
    }, [checked, quickInstallSelector, quickInstallList]);

    const showItem = useMemo(() => {
        if (checked.length >= quickInstallSelector.length) {
            return checkedItem[0];
        }
        if (checkedItem.length === 1) {
            return checkedItem[0];
        }
        return undefined;
    }, [checked, checkedItem, quickInstallSelector]);

    // 切换选项，更新展示内容
    const handleOnItemChange = useCallback((
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        groupKey: string,
        optionKey: string
    ): void => {
        setChecked(checked => {
            const quickInstallSelector = quickInstallSelectorRef.current || [];

            let newChecked: string[] = checked;
            // 如果点击已经选中的选项，会取消选中状态
            if (checked.indexOf(optionKey) > -1) {
                newChecked = newChecked.filter(key => key !== optionKey);
            }
            else {
                // 如果是新选中，则会将同一组的其他key取消
                const groupList = quickInstallSelector.find(group => group.groupKey === groupKey)?.list ?? [];
                // 同一组的其他key
                const sameGroupOptionKey = groupList.map(option => option.optionKey);
                const sameGroupOptionKeySet = new Set(sameGroupOptionKey);
                newChecked = [
                    ...checked.filter(key => !sameGroupOptionKeySet.has(key)),
                    optionKey
                ];
            }
            if (groupKey === '芯片厂商') {
                const platform = quickInstallSelector.find(group => group.groupKey === '计算平台');
                const platOptions = platform?.list;
                if (platOptions && platOptions.length > 0) {
                    const platformKey = platOptions.map(item => item.optionKey);

                    platformKey.forEach(key => {
                        if (newChecked.indexOf(key) !== -1) {
                            newChecked.splice(newChecked.indexOf(key), 1);
                        }
                    });
                }
            }
            return newChecked;
        });
    }, [setChecked, quickInstallSelectorRef]);

    useEffect(() => {
        if (typeof (localStorage) === 'object') {
            localStorage.setItem('quickInstallHooksChecked', JSON.stringify(checked));
        }
    }, [checked]);

    return {
        suffixText,
        quickInstallSelector,
        quickInstallList,
        checked,
        disabled,
        showItem,

        setSuffixText,
        setQuickInstallList,
        setQuickInstallSelector,
        setChecked,
        handleOnItemChange
    };
};

export default useQuickInstallHooks;
