/**
 * @file 快速开始组件
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import MarkdownContainer from '../../../../components/markdown-container/MarkdownContainer';
import { getCodeBlock } from '../../../../components/markdown-container/CodeBlock';

import { IQuickInstallProps } from './type';

const QuickInstall: React.FC<IQuickInstallProps> = React.memo(props => {
    const {
        suffixText,
        trackeventOptLabel,

        quickInstallSelector,
        checked,
        disabled,
        showItem,

        handleOnItemChange
    } = props;
    const contentRef = useRef<HTMLDivElement>(null);

    const [isPushedTrackevent, setIsPushedTrackevent] = useState<boolean>(false);

    // 如果有展示信息，则触发一次埋点事件
    useEffect(() => {
        if (!isPushedTrackevent && (window as any)._hmt && showItem) {
            (window as any)._hmt.push(['_trackEvent', '首页', '快速安装,方案生成', trackeventOptLabel]);
            setIsPushedTrackevent(true);
        }
    }, [isPushedTrackevent, showItem, trackeventOptLabel]);

    // 如果选项变化，则触发一次埋点事件
    useEffect(() => {
        if ((window as any)._hmt && showItem) {
            (window as any)._hmt.push(['_trackEvent', '首页', '快速安装,方案选择', JSON.stringify(checked)]);
        }
    }, [checked, showItem]);

    // 查看全部安装步骤，触发点击事件
    useEffect(() => {
        if ((window as any)._hmt && contentRef.current) {
            const dom = contentRef.current;
            const aList: HTMLAnchorElement[] = Array.from(dom.querySelectorAll('a'));
            const handleClick = (event: MouseEvent) => {
                const aDom: HTMLAnchorElement | null = event.target as HTMLAnchorElement;
                const url = aDom?.href || '';
                if (url) {
                    (window as any)._hmt.push([
                        '_trackEvent',
                        '首页',
                        '快速安装,查看全部安装步骤',
                        JSON.stringify({
                            链接: url,
                            选项: checked
                        })
                    ]);
                }
            };
            const targetList = aList.filter(aDom => {
                return aDom.innerHTML.includes('查看全部安装步骤');
            });
            targetList.forEach(aDom => {
                aDom.addEventListener('click', handleClick);
            });
            return () => {
                targetList.forEach(aDom => {
                    aDom.removeEventListener('click', handleClick);
                });
            };
        }
    }, [suffixText, checked]);

    // 如果点击复制按钮，则触发一次埋点事件
    const onMarkdownCopyClick = useCallback(
        (value?: string) => {
            if (!value) {
                return;
            }
            if (value.includes(' install ') || value.includes('docker pull')) {
                if ((window as any)._hmt) {
                    (window as any)._hmt.push([
                        '_trackEvent',
                        '首页',
                        '快速安装,方案生成',
                        JSON.stringify({
                            安装命令: value,
                            选项: checked
                        })
                    ]);
                }
            }
        },
        [checked]
    );

    const checkedSet = useMemo(() => {
        return new Set(checked);
    }, [checked]);
    const disabledSet = useMemo(() => {
        return new Set(disabled);
    }, [disabled]);

    // useEffect(() => {
    //     let timer: any;
    //     const getButtonDom = () => {
    //         const cuda116Dom = document.querySelector(
    //             '.paddle-quick-start-item:nth-child(4) .ant-btn:nth-child(2)'
    //         ) as HTMLButtonElement;
    //         const cuda117Dom = document.querySelector(
    //             '.paddle-quick-start-item:nth-child(4) span:nth-child(1)'
    //         ) as HTMLButtonElement;
    //         if (!cuda116Dom || !cuda117Dom) {
    //             timer = setTimeout(() => {
    //                 getButtonDom();
    //             }, 100);
    //         } else {
    //             clearTimeout(timer);
    //             if (checked.includes('paddleversion') && checked.includes('linux') && checked.includes('docker')) {
    //                 cuda116Dom.style.display = 'none';
    //                 cuda117Dom.style.display = 'block';
    //             } else {
    //                 cuda116Dom.style.display = 'block';
    //                 cuda117Dom.style.display = 'none';
    //             }
    //         }
    //     };
    //     getButtonDom();
    // }, [checked]);

    return (
        <div className="paddle-quick-start">
            {quickInstallSelector.length > 0 && (
                <div className="paddle-quick-start-content">
                    {quickInstallSelector.map(group => (
                        <div key={group.groupKey} className="paddle-quick-start-item">
                            <div className="paddle-quick-start-item-title">
                                {group.groupName}
                                {!!group.tips && (
                                    <Tooltip
                                        overlayClassName="paddle-quick-start-item-tip"
                                        placement="bottom"
                                        title={group.tips}
                                    >
                                        <QuestionCircleOutlined className="paddle-quick-start-item-title-tip-icon" />
                                    </Tooltip>
                                )}
                            </div>
                            <div className="paddle-quick-start-item-children">
                                <Button.Group size="large">
                                    {(() => {
                                        // const allOptionKey = group.list.map(item => item.optionKey);

                                        // if (allOptionKey.every(item => disabledSet.has(item))) {
                                        //     return (
                                        //         <Button
                                        //             // key={btnItem.optionKey}
                                        //             className={classNames({
                                        //                 // large: arr.length <= 2,
                                        //                 // checked: true,
                                        //                 disabled: true
                                        //             })}
                                        //             disabled={true}
                                        //         >
                                        //             无计算平台
                                        //         </Button>
                                        //     );
                                        // }

                                        return group.list.map((btnItem, btnItemIndex, arr) => {
                                            if (disabledSet.has(btnItem.optionKey) && group.groupName === '计算平台') {
                                                return null;
                                            }
    
                                            return (
                                                <Tooltip
                                                    key={btnItem.optionKey}
                                                    overlayClassName="paddle-quick-start-item-tip"
                                                    placement="top"
                                                    title={
                                                        disabledSet.has(btnItem.optionKey) ? group.disabledTooltip : undefined
                                                    }
                                                >
                                                    <Button
                                                        key={btnItem.optionKey}
                                                        className={classNames({
                                                            large: arr.length <= 2,
                                                            checked: checkedSet.has(btnItem.optionKey),
                                                            // disabled: disabledSet.has(btnItem.optionKey)
                                                        })}
                                                        // disabled={disabledSet.has(btnItem.optionKey)}
                                                        onClick={event =>
                                                            handleOnItemChange(event, group.groupKey, btnItem.optionKey)
                                                        }
                                                    >
                                                        {btnItem.optionName}
                                                    </Button>
                                                </Tooltip>
                                            );
                                        });
                                    })()}
                                </Button.Group>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!!showItem?.context && (
                <div className="paddle-quick-start-result">
                    {showItem.context.map(item => (
                        <div className="paddle-quick-start-result-item" key={item.title}>
                            <div className="paddle-quick-start-result-title">{item.title}</div>
                            <div className="paddle-quick-start-result-text" ref={contentRef}>
                                <MarkdownContainer
                                    source={item.text}
                                    renderers={{
                                        code: getCodeBlock({
                                            onCopyClick: onMarkdownCopyClick
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="paddle-quick-start-suffixtext" {...{ dangerouslySetInnerHTML: { __html: suffixText } }} />
        </div>
    );
});

export default QuickInstall;
